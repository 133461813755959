<template>
  <div v-loading="isLoading" class="image-warehouse">
    <div class="tabs">
      <div
        v-for="(item, i) in tabList"
        :key="i"
        class="tab-box"
        :class="{ active: i === activeColor }"
        @click="tabsChange(i, item)"
      >{{ item }}</div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        style="width: 100%; height: auto"
      >
        <el-table-column v-if="currentTab==='ALL'" prop="name" label="镜像名称" show-overflow-tooltip />
        <el-table-column v-if="currentTab!=='ALL'" prop="imageName" label="镜像名称" show-overflow-tooltip />
        <el-table-column
          v-if="currentTab!=='ALL'"
          prop="version"
          label="镜像版本"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="currentTab!=='ALL'"
          prop="type"
          label="镜像类型"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="currentTab==='ALL'"
          prop="pull_count"
          label="下载次数"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="currentTab==='ALL'"
          prop="artifact_count"
          label="使用次数"
          show-overflow-tooltip
        />
        <!-- <el-table-column
          v-if="currentTab==='ALL'"
          prop="owner"
          label="所有者"
          show-overflow-tooltip
        /> -->
        <el-table-column
          v-if="currentTab==='ALL'"
          prop="creation_time"
          label="创建时间"
          :formatter="formatTime"
          show-overflow-tooltip
        />
      </el-table>
      <div class="pagination">
        <div class="total">合计：{{ totalPage }}</div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          layout="sizes"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <div class="jumper">
          <div>{{ pageNum }}/{{ pagenumber }}</div>
          <div class="jumper-line" />
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          background
          layout="prev, next"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getImagesList, getImagesVersionList } from '@/api/ai'
import { formatTime } from '@/utils/formatTime'
export default {
  data() {
    return {
      repo: 'airepo',
      isLoading: false,
      activeColor: 0,
      tabList: ['ALL'],
      currentTab: 'ALL',
      pageSize: 10,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      tableData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatTime({ creation_time }) {
      return formatTime(`${creation_time}`, '{0}-{1}-{2} {3}:{4}:{5}')
    },
    // 获取all以及标签
    getData() {
      this.isLoading = true
      this.tabList = ['ALL']
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize
      }
      getImagesList(this.repo, data).then((response) => {
        this.isLoading = false
        this.tableData = response.data.content
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
        this.tableData.forEach(e => {
          e.name = e.name.split('/')[1]
          this.tabList.push(e.name)
        })
      }).catch(() => {
        this.isLoading = false
      })
    },
    // 获取镜像下版本信息
    getImagesVersion(val) {
      this.isLoading = true
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize
      }
      getImagesVersionList(this.repo, val, data).then((response) => {
        this.tableData = response.data.content
        this.isLoading = false
        this.pagenumber = response.data.totalpages
        this.totalPage = response.data.totalelements
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    // 切换tab
    tabsChange(i, item) {
      this.activeColor = i
      this.pageNum = 1
      this.currentTab = item
      if (item === 'ALL') {
        this.getData()
      } else {
        this.getImagesVersion(item)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-warehouse{
  padding: 16px 17px 16px 19px;
  height: 100%;
  width: 100%;
  overflow: auto;
  .tabs{
    display: flex;
    width: 100%;
    min-width: 930px;
    .tab-box{
      min-width: 92px;
      padding: 0 10px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 2px 2px 2px 2px;
      background: #F2F3F5;
      border-left: 1px solid #E5E6EB;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
    }
    .tab-box:first-child{
      border-left: none;
    }
    .active{
      color: #fff;
      background: #437AEC;
    }
  }
  .content{
    padding-top: 16px;
    width: 100%;
    min-width: 930px;
    ::v-deep .el-table .cell{
      color: #303133;
    }
  }
}
</style>
